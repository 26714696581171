import React, { useState, useEffect } from "react";
import axios from "axios";
import ProgressChart from "../charts/ProgressChart";
import UserMenu from "../partials/header/UserMenu";

const Reports = () => {
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  let loginToken = JSON.parse(localStorage.getItem("loginToken"));
  let project_ids = JSON.parse(localStorage.getItem("project_ids"));
  let name_list = JSON.parse(localStorage.getItem("name_list"));
  const headers = {
    Authorization: `Bearer ${loginToken}`,
    // Authorization: "Bearer" + " " + loginToken,
    "Content-Type": "application/json",
  };
  console.log(headers);

  let [device1Valuearr, setDevice1Valuearr] = useState([]);
  let [device2Valuearr, setDevice2Valuearr] = useState([]);
  let [device3Valuearr, setDevice3Valuearr] = useState([]);
  let [device4Valuearr, setDevice4Valuearr] = useState([]);
  let [device5Valuearr, setDevice5Valuearr] = useState([]);

  let [device1Datearr, setDevice1Datearr] = useState([]);

  const handleChange = (event) => {
    setSelectedDate(event.target.value);
    console.log(event.target.value);
  };

  let [report_id, setReportid] = useState();

  useEffect(() => {
    axios
      .get(
        `https://super-admin.smart-iot.in/api/super-admin/report/getreportpanel/${project_ids[0]}`,
        { headers }
      )
      .then(function (response) {
        console.log(response.data);
        // console.log(response.data.data.panels[0].id);
        let x = response.data.data;
        console.log(x);
        let report_id = x.panels[0]?.id;
        // setReportid(report_id);

        axios
          .get(
            `https://super-admin.smart-iot.in/api/super-admin/report/getreportdata/${report_id}/${selectedDate}`,
            { headers }
          )
          .then(function (response) {
            console.log(response.data.data);
            let tdays = response.data.data;
            let loop = response.data.data.length;
            // console.log(loop);
            //you need rto empty the array first otherwise you get the repeated set of arrays like: 5,10,15,...
            device1Valuearr = [];
            device2Valuearr = [];
            device3Valuearr = [];
            device4Valuearr = [];
            device5Valuearr = [];

            device1Datearr = [];

            for (let i = 0; i < loop; i++) {
              // param_23 and param_24 for decive 001 => we have to calculate the values using thiese two for device-001

              //   console.log(tdays[i].param_23, " ", tdays[i].param_24);
              let device1val = (
                (Number(tdays[i].param_23) * 65536 +
                  Number(tdays[i].param_24)) *
                0.01
              ).toFixed(3);
              //   console.log(device1val);

              // param_25 and param_26 for decive 002 => we have to calculate the value using these two for device-002
              let device2val = (
                (Number(tdays[i].param_25) * 65536 +
                  Number(tdays[i].param_26)) *
                0.01
              ).toFixed(3);
              //   console.log(device2val);

              // param_27 and param_28 for decive 003 => we have to calculate the value using these two for device-003
              let device3val = (
                (Number(tdays[i].param_27) * 65536 +
                  Number(tdays[i].param_28)) *
                0.01
              ).toFixed(3);
              //   console.log(device3val);

              // param_29 and param_30 for decive 004 => we have to calculate the value using these two for device-004
              let device4val = (
                (Number(tdays[i].param_29) * 65536 +
                  Number(tdays[i].param_30)) *
                0.01
              ).toFixed(3);
              //   console.log(device4val);

              // param_31 and param_32 for decive 004 => we have to calculate the value using these two for device-004
              let device5val = (
                (Number(tdays[i].param_31) * 65536 +
                  Number(tdays[i].param_32)) *
                0.01
              ).toFixed(3);
              //   console.log(device5val);

              device1Valuearr.push(device1val);
              device2Valuearr.push(device2val);
              device3Valuearr.push(device3val);
              device4Valuearr.push(device4val);
              device5Valuearr.push(device5val);

              //   console.log(tdays[i].date.parse);
              //   console.log(tdays[i].date.slice(0, 10));

              let day = tdays[i].date.slice(0, 10);

              device1Datearr.push(day);
            }
            setDevice1Valuearr(device1Valuearr);
            setDevice2Valuearr(device2Valuearr);
            setDevice3Valuearr(device3Valuearr);
            setDevice4Valuearr(device4Valuearr);
            setDevice5Valuearr(device5Valuearr);

            setDevice1Datearr(device1Datearr);

            // console.log(device1Valuearr);
            // console.log(device2Valuearr);
            // console.log(device3Valuearr);
            // console.log(device4Valuearr);
            // console.log(device5Valuearr);

            // console.log(device1Datearr);
          })
          .catch(function (err) {
            console.log(err);
          });
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [selectedDate]);

  //   console.log(report_id);

  //   useEffect(() => {
  //     axios
  //       .get(
  //         `https://super-admin.smart-iot.in/api/super-admin/report/getreportdata/${report_id}/${selectedDate}`,
  //         { headers }
  //       )
  //       .then(function (response) {
  //         console.log(response.data.data);
  //         let tdays = response.data.data;
  //         let loop = response.data.data.length;
  //         // console.log(loop);
  //         //you need rto empty the array first otherwise you get the repeated set of arrays like: 5,10,15,...
  //         device1Valuearr = [];
  //         device2Valuearr = [];
  //         device3Valuearr = [];
  //         device4Valuearr = [];
  //         device5Valuearr = [];

  //         device1Datearr = [];

  //         for (let i = 0; i < loop; i++) {
  //           // param_23 and param_24 for decive 001 => we have to calculate the values using thiese two for device-001

  //           //   console.log(tdays[i].param_23, " ", tdays[i].param_24);
  //           let device1val = (
  //             (Number(tdays[i].param_23) * 65536 + Number(tdays[i].param_24)) *
  //             0.01
  //           ).toFixed(3);
  //           //   console.log(device1val);

  //           // param_25 and param_26 for decive 002 => we have to calculate the value using these two for device-002
  //           let device2val = (
  //             (Number(tdays[i].param_25) * 65536 + Number(tdays[i].param_26)) *
  //             0.01
  //           ).toFixed(3);
  //           //   console.log(device2val);

  //           // param_27 and param_28 for decive 003 => we have to calculate the value using these two for device-003
  //           let device3val = (
  //             (Number(tdays[i].param_27) * 65536 + Number(tdays[i].param_28)) *
  //             0.01
  //           ).toFixed(3);
  //           //   console.log(device3val);

  //           // param_29 and param_30 for decive 004 => we have to calculate the value using these two for device-004
  //           let device4val = (
  //             (Number(tdays[i].param_29) * 65536 + Number(tdays[i].param_30)) *
  //             0.01
  //           ).toFixed(3);
  //           //   console.log(device4val);

  //           // param_31 and param_32 for decive 004 => we have to calculate the value using these two for device-004
  //           let device5val = (
  //             (Number(tdays[i].param_31) * 65536 + Number(tdays[i].param_32)) *
  //             0.01
  //           ).toFixed(3);
  //           //   console.log(device5val);

  //           device1Valuearr.push(device1val);
  //           device2Valuearr.push(device2val);
  //           device3Valuearr.push(device3val);
  //           device4Valuearr.push(device4val);
  //           device5Valuearr.push(device5val);

  //           //   console.log(tdays[i].date.parse);
  //           //   console.log(tdays[i].date.slice(0, 10));

  //           let day = tdays[i].date.slice(0, 10);

  //           device1Datearr.push(day);
  //         }
  //         setDevice1Valuearr(device1Valuearr);
  //         setDevice2Valuearr(device2Valuearr);
  //         setDevice3Valuearr(device3Valuearr);
  //         setDevice4Valuearr(device4Valuearr);
  //         setDevice5Valuearr(device5Valuearr);

  //         setDevice1Datearr(device1Datearr);

  //         // console.log(device1Valuearr);
  //         // console.log(device2Valuearr);
  //         // console.log(device3Valuearr);
  //         // console.log(device4Valuearr);
  //         // console.log(device5Valuearr);

  //         // console.log(device1Datearr);
  //       })
  //       .catch(function (err) {
  //         console.log(err);
  //       });
  //   }, [selectedDate]);

  //   console.log(device1Valuearr, " ", device1Datearr);

  return (
    <div className="mt-0  ">
      {/* <div className="flex flex-wrap"></div> */}
      <div className="sticky top-0 z-50 flex p-2 bg-black text-center font-sans font-semibold  justify-end align-middle items-center flex-wrap gap-6 ">
        <nav className="text-white  text-xl">MONTHLY REPORTS</nav>
        <header className="sticky top-0 z-30 bg-black p-1  flex flex-wrap justify-end items-center gap-2 ">
          <div className="grid justify-start grid-flow-col gap-2 sm:auto-cols-max sm:justify-end">
            <label htmlFor="datepicker" className="sr-only">
              Select Date
            </label>
            <input
              type="date"
              id="datepicker"
              value={selectedDate}
              onChange={handleChange}
              className="p-1 border rounded-lg sm:w-52 lg:w-56 md:w-60 xl:w-60 2xl:w-60"
            />
          </div>
        </header>
        <div className="p-1">
          <UserMenu />
        </div>
      </div>

      <div className="min-h-screen  bg-gradient-to-br from-black via-green-700 to-[#139c13] overflow-hidden ">
        {/* <div className="flex flex-wrap min-w-fit">{cards}</div> */}
        <div className="flex mb-2 flex-wrap justify-center items-center gap-3">
          <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-[#00FF00]  my-2 rounded-lg shadow-lg ">
            <div className="text-center font-bold text-xl font-sans p-2">
              TOTALIZED FLOW / MONTH
              <div>{name_list[0]}</div>
            </div>

            <ProgressChart
              dataArr={device1Valuearr}
              timeArr={device1Datearr}
              colour={"#00FF00"}
              date={selectedDate.slice(0, 7)}
              name={name_list[0]}
              tag={"Totalized Flow"}
            />
          </div>
          <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-[#00FF00]  my-2 rounded-lg shadow-lg ">
            <div className="text-center font-bold text-xl font-sans p-2">
              TOTALIZED FLOW / MONTH
              <div>{name_list[1]}</div>
            </div>

            <ProgressChart
              dataArr={device2Valuearr}
              timeArr={device1Datearr}
              colour={"#00FF00"}
              date={selectedDate.slice(0, 7)}
              name={name_list[1]}
              tag={"Totalized Flow"}
            />
          </div>
          <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-[#00FF00]  my-2 rounded-lg shadow-lg ">
            <div className="text-center font-bold text-xl font-sans p-2">
              TOTALIZED FLOW / MONTH
              <div>{name_list[2]}</div>
            </div>

            <ProgressChart
              dataArr={device3Valuearr}
              timeArr={device1Datearr}
              colour={"#00FF00"}
              date={selectedDate.slice(0, 7)}
              name={name_list[2]}
              tag={"Totalized Flow"}
            />
          </div>
        </div>

        <div className="flex mb-2 flex-wrap justify-center items-center gap-3">
          <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-[#00FF00]  my-2 rounded-lg shadow-lg ">
            <div className="text-center font-bold text-xl font-sans p-2">
              TOTALIZED FLOW / MONTH
              <div>{name_list[3]}</div>
            </div>

            <ProgressChart
              dataArr={device4Valuearr}
              timeArr={device1Datearr}
              colour={"#00FF00"}
              date={selectedDate.slice(0, 7)}
              name={name_list[3]}
              tag={"Totalized Flow"}
            />
          </div>
          <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-[#00FF00]  my-2 rounded-lg shadow-lg ">
            <div className="text-center font-bold text-xl font-sans p-2">
              TOTALIZED FLOW / MONTH
              <div>{name_list[4]}</div>
            </div>

            <ProgressChart
              dataArr={device5Valuearr}
              timeArr={device1Datearr}
              colour={"#00FF00"}
              date={selectedDate.slice(0, 7)}
              name={name_list[4]}
              tag={"Totalized Flow"}
            />
          </div>
        </div>
      </div>

      {/* <div>
    <Smsalert />
  </div> */}
    </div>
  );
};

export default Reports;
