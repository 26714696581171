import React, { useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useState } from "react";
import Smsalert from "./Smsalert";

const Home = () => {
  const cards = [];
  const options = [];

  const disconnectString = localStorage.getItem("disconnect");
  const disconnect = eval("(" + disconnectString + ")");

  let [mqttdata, setMqttData] = useState([]);
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));

  setInterval(() => {
    // useEffect(() => {
    let mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
    setMqttData(mqttdata);
    // }, [mqttdata]);
  }, 2000);

  // console.log(mqttdata);

  let imeilist = JSON.parse(localStorage.getItem("imeilist"));
  // console.log(imeilist);

  let device_ids = JSON.parse(localStorage.getItem("device_ids"));
  // console.log(device_ids);

  let location_list = JSON.parse(localStorage.getItem("location_list"));

  let name_list = JSON.parse(localStorage.getItem("name_list"));

  let project_name = JSON.parse(localStorage.getItem("project_name"));

  function handleClick(imei, deviceid, cardid) {
    const data = { imei, deviceid, cardid };
    localStorage.setItem("data", JSON.stringify(data));
  }
  // imeilist.length
  for (let i = 0; i < imeilist.length; i++) {
    options.push({
      value: i,
      label: `Device ${i + 1}`,
    });
    cards.push(
      //   <Link to="/Dashboard">
      <div
        key={i}
        id={`card${i}`}
        className=" w-auto h-auto sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/5 sm:h-1/3 md:h-1/3 lg:h-1/3 xl:h-1/5 2xl:h-1/4 2xl:w-1/6 3xl:h-1/4 3xl:w-1/6 p-10 mt-2 shadow-[#00FF00] ease-in hover:shadow-4xl transition-shadow hover:shadow-stone-900 
    max-w-sm max-h-full rounded-lg overflow-hidden shadow-lg m-2 cursor-pointer flex-grow bg-white bg-opacity-25 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50 "
      >
        <div className="border-b-4 border-[#22ad22] mb-5">
          <Link
            to="/Dashboard"
            onClick={() => handleClick(imeilist[i], device_ids[i], i)}
          >
            <h2 className="pb-1 font-sans text-xl font-semibold text-center text-white">
              {location_list[i]}
            </h2>
            <h3 className="pb-1 font-mono text-sm font-semibold text-center text-white">
              ( {name_list[i]} )
            </h3>
            <div className="pb-2 font-mono text-xs font-semibold text-center text-white">
              ({imeilist[i]})
            </div>
          </Link>
        </div>

        <div className="flex flex-wrap items-center justify-center gap-2 align-middle">
          <div className="flex-1 block h-full p-2 m-1 text-sm font-bold text-center text-black transition duration-300 ease-in-out bg-gray-200 border-2 border-gray-100 rounded-lg shadow-xl focus:outline-none">
            TOTALIZED FLOW
            <div className="block p-2 m-1 text-sm font-bold text-white transition duration-300 ease-in-out rounded-lg shadow-xl bg-gradient-to-br from-green-800 to-green-600 hover:text-white focus:outline-none ">
              <div></div>
              <span>
                {mqttdata?.[i]
                  ? (
                      (Number(mqttdata[i][0]) * 65536 +
                        Number(mqttdata[i][1])) /
                      1000
                    ).toFixed(3)
                  : 0}{" "}
                m <sup>3</sup>
              </span>
            </div>
          </div>
          <div className="flex-1 block h-full p-2 m-1 text-sm font-bold text-center text-black transition duration-300 ease-in-out bg-gray-200 border-2 border-gray-100 rounded-lg shadow-xl focus:outline-none ">
            FLOW RATE
            <div className="block p-2 m-1 text-sm font-bold text-white transition duration-300 ease-in-out rounded-lg shadow-xl bg-gradient-to-br from-green-800 to-green-600 hover:text-white focus:outline-none">
              <div></div>
              <span>
                {mqttdata?.[i]
                  ? (
                      (Number(mqttdata[i][2]) * 65536 +
                        Number(mqttdata[i][3])) /
                      10
                    ).toFixed(2)
                  : 0}{" "}
                m <sup>3</sup>/hr
              </span>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-wrap items-center justify-center ">
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl  border-2 border-gray-100 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm  ">
            Incubator-3
            <p className="block p-1 m-1 text-sm font-bold text-white transition duration-300 ease-in-out rounded-lg shadow-xl bg-gradient-to-br from-cyan-800 to-cyan-600 hover:text-white focus:outline-none">
              <div>Temperature</div>
              <span>{mqttdata?.[i] ? mqttdata[i][100] / 10 : 0} ° C</span>
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl  border-2 border-gray-100 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm  ">
            Hot Air Oven
            <p className="block p-1 m-1 text-sm font-bold text-white transition duration-300 ease-in-out rounded-lg shadow-xl bg-gradient-to-br from-cyan-800 to-cyan-600 hover:text-white focus:outline-none">
              <div>Temperature</div>
              <span>{mqttdata?.[i] ? mqttdata[i][150] / 10 : 0}° C</span>
            </p>
          </p>
        </div> */}

        <Link
          to="/Dashboard"
          onClick={() => handleClick(imeilist[i], device_ids[i], i)}
        >
          <div className="mt-2 text-center">
            <button className="p-2 text-white bg-green-700 shadow-lg btn btn-primary shadow-black">
              Show more..
            </button>
          </div>
        </Link>
      </div>
    );
  }

  function scrolltoCard(value) {
    const element = document.getElementById(`card${value}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const handleChange = (selectedOption) => {
    // selectedOption.scrollIntoView();
    let cardvalue = selectedOption.value;
    scrolltoCard(cardvalue);
    // cardno.scrollIntoView();
    console.log(selectedOption);
  };

  return (
    <div className="mt-0 ">
      {/* <div className="flex flex-wrap"></div> */}
      <div className="sticky top-0 z-50 flex flex-wrap justify-end gap-6 p-2 font-sans font-semibold text-center bg-black ">
        <nav className="text-xl text-white">FLOW DATA LOGGER</nav>
        <div className="flex justify-end">
          <Select options={options} onChange={handleChange} />
          <Link
            className="flex items-center px-3 py-1 text-sm font-medium text-[#00FF00] hover:text-white"
            to="/Reports"
            
          >
            Reports
          </Link>
          <Link
            className="flex items-center px-3 py-1 text-sm font-medium text-[#00FF00] hover:text-white"
            to="/"
            onClick={() => {
              // disconnect();
              // localStorage.clear();
            }}
          >
            Log Out
          </Link>
        </div>
      </div>

      <div className="min-h-screen  bg-gradient-to-br from-black via-green-700 to-[#139c13] overflow-hidden ">
        <div className="flex flex-wrap min-w-fit">{cards}</div>
      </div>

      {/* <div>
        <Smsalert />
      </div> */}
    </div>
  );
};

export default Home;
